<template>
	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search d-flex" @keyup.enter.native="$refs.table.reload()"
				@submit.native.prevent>


				<el-form-item label="省:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-150">
					<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
						<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-150">
					<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
						<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="县/区:" style="display: flex ; align-items: center; padding-left: 10px;" class="w-220">
					<el-select v-model="table.where.aid" placeholder='请选择县/区' style="width: 170px ;" @change="$forceUpdate()"
						clearable>
						<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
							:label="option.name"></el-option>
					</el-select>
				</el-form-item>





				<el-form-item label="订单号:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.order_id" placeholder="请输入订单编号" clearable />
				</el-form-item>

				<el-form-item label="订单状态:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-select v-model="table.where.status" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="100" />
						<el-option label="已完成" value="10" />
						<el-option label="进行中" value="3" />
						<el-option label="待接单" value="1" />
						<el-option label="已失效" value="2" />
						<el-option label="已取消" value="22" />
						<el-option label="手动结束" value="99" />
					</el-select>
				</el-form-item>

				<el-form-item label="订单类型:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-select v-model="table.where.type" placeholder="请选择" clearable class="ele-fluid">
						<el-option label="全部" value="" />
						<!-- <el-option label="模糊电召" value="4" /> -->
						<el-option label="电召叫单" value="2" />
					</el-select>
				</el-form-item>

				<el-form-item label="订单时间：" style="display: flex ; align-items: center; padding-left: 10px; margin-right: 10px;"
					clearable>
					<el-date-picker v-model="value" @change="birthdayChange" type="datetimerange"
						value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期"
						end-placeholder="结束日期" :default-time="['00:00:00']">
					</el-date-picker>
				</el-form-item>

				<!-- <el-form-item label="订单开始时间：" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-date-picker :picker-options="pickerOptionsStart" style="margin-right: 10px" popper-class="no-atTheMoment"
						@change="birthdayChange" v-model="tableCreate_time" value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
				</el-form-item>

				<el-form-item label="订单结束时间：" style="display: flex ;  padding-left: 10px;">
					<el-date-picker :picker-options="pickerOptionsEnd" style="margin-right: 10px" popper-class="no-atTheMoment"
						@change="birthdayChange1" v-model="tableend_time" value-format="yyyy-MM-dd HH:mm:ss"
						format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
				</el-form-item> -->

				<el-form-item label="老人姓名:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.help_name" placeholder="请输入姓名" clearable />
				</el-form-item>

				<el-form-item label="老人手机号/座机号:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.help_phone" placeholder="请输入手机号或座机号" clearable />
				</el-form-item>

				<el-form-item label="接单司机:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.driver_name" placeholder="请输入姓名" clearable />
				</el-form-item>

				<el-form-item label="车牌号:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.driver_carnumber" placeholder="请输入车牌号" clearable />
				</el-form-item>

				<el-form-item label="司机手机号:" style="display: flex ; align-items: center; padding-left: 10px;">
					<el-input v-model="table.where.driver_phone" placeholder="请输入司机手机号" clearable />
				</el-form-item>



				<el-form-item style="padding-left: 10px;">

					<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
						class="ele-btn-icon">搜索</el-button>
					<el-button @click="reset_search()">重置</el-button>

					<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="order/exportExcelHelpAge"
						:params="exportOrderParams()" :outtime='6000' v-if="permission.includes('sys: callList:export')"
						ref="childDownload" />



				</el-form-item>
			</el-form>

			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :cell-class-name="nopaddingCell" :choose.sync="choose"
				highlight-current-row :stripe=true>
				<template slot-scope="{index}">

					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="city_name" label="所属地区" show-overflow-tooltip min-width="180" />
					<el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="200" />
					<el-table-column prop="channel_name" label="订单类型" show-overflow-tooltip min-width="110" />
					<el-table-column prop="create_time" label="发单时间" show-overflow-tooltip min-width="110" />
					<!-- <el-table-column label="发单时间" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
					</el-table-column> -->

					<el-table-column prop="help_name" label="老人姓名" show-overflow-tooltip min-width="120" />
					<el-table-column prop="phone" label="手机号/座机号" show-overflow-tooltip min-width="140" />
					<el-table-column prop="driver_name" align="center" label="接单司机" show-overflow-tooltip min-width="140" />
					<el-table-column prop="car_number" label="车牌号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="driver_phone" label="司机手机号" show-overflow-tooltip min-width="120" />

					<el-table-column prop="order_price" label="订单金额" align="center" show-overflow-tooltip min-width="120" />
					<el-table-column label="订单状态" align="center" show-overflow-tooltip min-width="160">
						<template slot-scope="{row}">
							<div class="status">
								{{ row.status_name }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="feedback" label="司机反馈" show-overflow-tooltip min-width="120" />
					<el-table-column prop="admin_name" label="手动结束账号" show-overflow-tooltip min-width="120" />
					<el-table-column prop="finish" label="结束原因" show-overflow-tooltip min-width="120" />
					<el-table-column label="操作" width="300px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link v-if="permission.includes('sys:zhulao-order:detail')" @click="handlecheck(row)" type="primary"
								:underline="false">查看
							</el-link>
							<el-link v-if="permission.includes('sys:zhulao-order:remove')" @click="helpdel(row)" type="danger"
								:underline="false">删除
							</el-link>
							<el-link v-if="permission.includes('sys:zhulao-order:repeat')" @click="followUp(row.phone)" type="success"
								:underline="false">回访入库
							</el-link>
							<el-link
								v-if="permission.includes('sys:callList:viewtrack') && ((row.status_name.indexOf('已完成') != -1 && row.driver_id) || row.status_name.indexOf('进行中') != -1 || row.status_name.indexOf('空驶补偿') != -1)"
								@click="viewTrack(row)" type="primary" :underline="false">查看轨迹</el-link>
							<el-link
								v-if="row.status !== 2 && row.status !== 10 && permission.includes('sys:zhulao-order:order_finish')"
								@click="overOrder(row)" type="primary" :underline="false">手动结束</el-link>
							<!-- <el-dropdown @command="command => dropClick(command, row)"
								v-if="permission.includes('sys:callList:delete')">
								<span class="el-dropdown-link" style="margin-left: 10px;">
									<el-link type="primary" icon="el-icon-more" :underline="false"></el-link>
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-delete" command="remove"
										v-if="permission.includes('sys:callList:delete')">删除</el-dropdown-item>
								</el-dropdown-menu>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item icon="el-icon-delete" command="remove"
										v-if="permission.includes('sys:callList:delete')">回访入库</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown> -->

						</template>

					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
		<!-- 查看订单详情 -->
		<el-dialog v-dialogDrag title="查看订单详情" :visible.sync="dialogTableVisibleCheck" @close='closeShow'
			:destroy-on-close="true" :lock-scroll="false">
			<el-card shadow="never" v-if="isReloadData">
				<el-form :model="checkForm" label-width="140px">
					<div style="display: flex;">
						<div style="width:100%;box-sizing: border-box;padding: 0 50px 0 10px;">
							<el-form-item label="发单时间：" prop="create_time">
								{{ checkForm.create_time }}
							</el-form-item>
							<el-form-item label="订单号：" prop="order_id">
								{{ checkForm.order_id }}
							</el-form-item>
							<el-form-item label="订单类型：" prop="type_name">
								{{ checkForm.channel_name }}
							</el-form-item>
							<el-form-item label="发单起点所在地：" prop="place_address">
								{{ checkForm.place_address }}
							</el-form-item>
							<el-form-item label="发单终点所在地：" prop="target_address">
								{{ checkForm.target_address }}
							</el-form-item>
							<el-form-item label="语音时长：" prop="jiequ_audio_length">
								{{ checkForm.jiequ_audio_length }}秒
							</el-form-item>
							<el-form-item label="用户语音：" prop="jiequ_audio">
								<audio controls="controls" v-if="isReloadData">
									<source :src="checkForm.jiequ_audio" type="audio/ogg">
									<source :src="checkForm.jiequ_audio" type="audio/mpeg">
								</audio>

							</el-form-item>
							<el-form-item label="接单司机：" prop="driver_name">
								{{ checkForm.driver_name }}
							</el-form-item>
							<el-form-item label="司机手机号：">
								{{ checkForm.driver_phone }}
							</el-form-item>
							<el-form-item label="接单时间：" prop="receive_time">
								{{ checkForm.receive_time * 1000 | toDateString }}
							</el-form-item>
							<!-- <el-form-item label="完成时间：" prop="complete_time">
								{{ checkForm.complete_time*1000 | toDateString }}
							</el-form-item> -->
							<el-form-item label="支付方式：" prop="remark">
								{{ checkForm.remark }}
							</el-form-item>
							<el-form-item label="订单状态：" prop="status_name">
								{{ checkForm.status_name }}
							</el-form-item>
							<el-form-item label="司机反馈：" prop="feedback">
								{{ checkForm.feedback }}
							</el-form-item>

						</div>
					</div>
				</el-form>
			</el-card>
		</el-dialog>
		<!-- 查看轨迹 -->
		<el-dialog v-dialogDrag title="查看车辆行驶轨迹" :visible.sync="trackShow" @closed="editForm = {}" :destroy-on-close="true"
			:lock-scroll="false">
			<ElDialogMapTrack :options="options"></ElDialogMapTrack>
		</el-dialog>
		<!-- 手动结束弹窗 -->
		<el-dialog v-dialogDrag title="确定手动结束" :visible.sync="overOrderShow" :destroy-on-close="true" :lock-scroll="false">
			<el-form :model="overOrderList2" ref="overOrderList" @submit.native.prevent :rules="overOrderRules"
				style="margin: 20px 0;">
				<el-form-item label="手动结束原因:" label-width="120px" prop="finish">
					<el-input type="textarea" v-model="overOrderList2.finish" placeholder="手动结束原因" style="width: 450px;" />
				</el-form-item>
			</el-form>
      
			<div slot="footer">
				<el-button @click="overOrderShow=false,overOrderList2.finish=''">取消</el-button>
				<el-button type="primary" @click="order_status_update(overOrderList)">确认</el-button>
			</div>
      
		</el-dialog>
	</div>
</template>
<!--<link rel="stylesheet" href="https://a.amap.com/jsapi_demos/static/demo-center/css/demo-center.css" />-->

<!-- <script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script> -->
<!-- <script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script> -->
<script>
	import ElDialogMapTrack from '@/components/ElDialogMapTrack'
	import download from '@/components/ListDownload/index'
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "SysUser",
		components: {
			download,
			ElDialogMapTrack
		},
		data() {
			return {
				trackShow: false, //轨迹弹窗控制
				options: {
					row: {},
					lineArr: ''
				},
				isReloadData: true,
				pickerOptionsStart: {
					disabledDate: time => {
						let endDateVal = this.tableend_time;
						if (endDateVal) {
							return time.getTime() > new Date(endDateVal).getTime();
						}
					}
				},
				pickerOptionsEnd: {
					disabledDate: time => {
						let beginDateVal = this.tableCreate_time;
						if (beginDateVal) {
							return (
								time.getTime() <
								new Date(beginDateVal).getTime()
							);
						}
					}
				},
				isPlay: true,
				tableCreate_time: '', //搜索选中时间戳
				tableend_time: '',
				bgWidth: '',
				aa: true,
				ampDisplay: false,
				dialogVisibleAdd: false,
				gStar: [0, 0, 0, 0, 0],
				gStartext: [false, false, false, false, false],
				charList: [],
				table: {
					url: '/order/helpAgeOrderIndex',
					where: {

					}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则
					phone: [{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					}, {
						pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号',
						trigger: 'blur',
					}],
					name: [{
						required: true,
						message: '请输入昵称',
						trigger: 'blur'
					}],
					sex: [{
						required: true,
						message: '请输入性别，1男2女',
						trigger: 'blur'
					}],
					home: [{
						required: true,
						message: '请输入家乡名称，对应aid',
						trigger: 'blur'
					}],

					money: [{
						required: true,
						message: '请输入余额',
						trigger: 'blur'
					}],

					losemoney: [{
						required: true,
						message: '请输入已提现/使用余额',
						trigger: 'blur'
					}],

				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleChatRecord: false,
				provArr: [],
				time: [],
				cityArr: [],
				districtArr: [],
				audioArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				zoom: 12,
				center: [104.07, 30.67],
				ampSrc: '',
				driverInfo: {},
				orderInfo: {},
				audio: '',
				autoPlays: false,
				finish: '', //手动结束原因
				overOrderShow: false,
				overOrderList: {},
				overOrderList2:{},
				overOrderRules: {
					finish: [{
						required: true,
						message: '请输入结束原因',
						trigger: 'blur'
					}]
				},
				value: '',
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})

			var url = this.$route.query.orderId
			if (url != undefined && url != '') {
				this.dialogTableVisibleChatRecord = true
				this.getChat(url)
			} else {
				this.dialogTableVisibleChatRecord = false
			}
			console.log('');
		},
		computed: {
			...mapGetters(["permission"]),
		},

		mounted() {},
		methods: {
			nopaddingCell(val) {
				if (!val.column.label) {
					return "";
				}
				if (val.column.label.indexOf("订单状态") == -1) {
					return ""
				}

				if (!val.row.status_name) {
					return "";
				}
				let e = val.row.status_name;

				if (!e) {
					return "nopaddingCell"
				} else if (e.indexOf('已完成') != -1) {
					return 'cellDone'
				} else if (e.indexOf('进行中') != -1) {
					return 'cellProcess'
				} else if (e.indexOf('取消') != -1 || e.indexOf('失效') != -1) {
					return 'cellCancel'
				} else if (e.indexOf('待接单') != -1) {
					return 'cellWait'
				}

				return "nopaddingCell";
			},
			//查看轨迹
			viewTrack(row) {
				console.log('row', row);
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/driver/getOneTrajectory', {
					order_id: row.order_id
				}).then(res => {
					loading.close();
					this.options.row = row;
					this.options.lineArr = res.data.data.driver_place;
					this.trackShow = true;
				})
			},
			//回访入口按钮
			followUp(phone) {
				this.$router.push({
					path: '/system/user/zhulao',
					query: {
						phone
					}
				})
			},
			mohuClick() {
				this.$refs.MusicPlay.src = this.$root.lingTiShiAudio;
				//console.log('22222mplay',this.$refs.MusicPlay.src);
				this.$refs.MusicPlay.play();


			},
			yichangClick() {
				this.$refs.MusicPlay.src = this.$root.lingdangAudio;
				//console.log('111111mplay',this.$refs.MusicPlay.src);
				this.$refs.MusicPlay.play();


			},
			ReloadData() {
				this.isReloadData = false
				this.$nextTick(() => {
					this.isReloadData = true
				})
			},
			birthdayChange() {
				console.log('value', this.value);
				if (this.value) {
					this.$set(this.table.where, 'create_time', this.value)
				} else {
					this.table.where.create_time = []
				}
				//var time = [];
				//				if(this.table.where.create_time.length >0){
				// this.time[0] = this.tableCreate_time
				// this.table.where.create_time = this.time;
				//				}else{
				//					(this.table.where.create_time).unshift(this.tableCreate_time)
				//				}
				//console.log(this.tableCreate_time)
				//console.log(this.table.where.create_time)
			},
			birthdayChange1() {
				//if(this.table.where.create_time.length >0){
				this.time[1] = this.tableend_time
				this.table.where.create_time = this.time;
				//console.log(this.table.where.create_time)

				//				}else{
				//					(this.table.where.create_time).push(this.tableend_time)
				//				}
				//				(this.table.where.create_time).push(this.tableend_time)
			},
			// 导出数据参数
			exportOrderParams() {
				const query = this.table.where
				const params = this.params

				//console.log('-----11---params------------',params)
				//console.log('-----22---query------------',query)
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			//重置
			reset_search() {

				this.value = '';
				this.$refs.childDownload.clearWhere();
				this.table.where = {
					source: 2
				}
				this.tableCreate_time = '';
				this.tableend_time = '';
				this.$refs.table.reload()
			},
			hanlePicture(src) {
				this.ampDisplay = true
				this.ampSrc = src
			},
			handleClose() {
				this.ampDisplay = false
				this.autoPlays = false
			},
			closeShow() {

				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].currentTime = 12
					middlePlay[i].pause()
				}
				this.autoPlays = false

			},
			closeDialog() {
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					middlePlay[i].currentTime = 12
					middlePlay[i].pause()
				}
			},
			handlePlay(url, id, index) {
				let that = this
				var middlePlay = document.querySelectorAll('.pasuVideo')
				middlePlay[0].currentTime = 12
				middlePlay[0].pause()
				var middle = 'middleindex'
				middlePlay = this.$refs[middle]
				middlePlay.play()
				that.isPlay = false
				middlePlay.addEventListener('ended', function() {
					that.isPlay = true
				})


			},
			dropClick(command, row) {
				if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'chat') { //紧急联系人
					this.handleChat(row)
				} else if (command === 'getChat') { //聊天记录
					this.getChat(row)
				}
			},
			save() {},
			// 聊天记录
			getChat(id) {
				const loading = this.$loading({
					lock: true,
					target: document.querySelector(".records")
				});
				this.$http.post('/order/chat_log', {
					id: id
				}).then(res => {
					var data = res.data
					loading.close()
					data.log.forEach(function(item) {
						item.isTimeShow = 1
						if (item.content.S == 21) {
							item.isPlay = true
							if (item.content.P.LONG == 1) {
								item.bgWidth = '8rem'
							}
							item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
						}
						if (item.content.S == 26) {
							item.content.P.POINT = item.content.P.POINT.split(',')
							//console.log(item.content.P.POINT)

						}
					});
					for (var i = 0; i < data.log.length; i++) {
						for (var j = i + 1; j < data.log.length - 1; j++) {
							if (data.log[i].create_time == data.log[j].create_time) {
								data.log[j].isTimeShow = 0 //不显示时间
							} else {
								data.log[j].isTimeShow = 1
							}
						}
					}
					this.charList = data.log
					this.driverInfo = data.driver
					this.orderInfo = data.order_info
				})

			},
			handleChat(row) {
				this.dialogTableVisibleChatRecord = true
				this.getChat(row.id)
			},
			/***选择省**/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					// //console.log(this.cityArr)
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/***选择市**/
			handleChangeCity(e) {
				if (e) {
					/** 获取被选市的cid**/
					var cid = ''
					this.cityArr.forEach(function(item) {
						if (item.cid == e) {
							cid = item.cid
						}
					})
					/** 根据被选市的cid获取市下面的县**/
					this.$http.post('/common/area_list', {
						cid: cid
					}).then(res => {
						var data = JSON.parse(res.data)
						this.districtArr = data
						/** 选择省份清空县**/
						this.table.where.aid = ''
					})
				} else {
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			/***查看用户信息**/
			handlecheck(row) {

				this.dialogTableVisibleCheck = true
				this.checkForm = row;
				this.autoPlays = true
				this.isPlay = false
				this.ReloadData()

			},
			endaudio(row) {
				var that = this
				that.isPlay = true
			},
			loadedmetadata() {
				//console.log('loadedmetadata');
				var middlePlay = document.querySelectorAll('.pasuVideo')
				for (let i = 0; i < middlePlay.length; i++) {
					//console.log(middlePlay[i]);
					middlePlay[i].currentTime = 12;
					middlePlay[i].pause()
				}
			},
			canplay() {

			},

			/*** 刪除(批量刪除)*/
			helpdel(row) {
				if (!row) { // 批量删除
					if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
					let ids = this.choose.map(d => d.id);
					this.$confirm('确定要删除选中的订单吗?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/order/helpdel', {
							id: ids
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					}).catch(() => 0);
				} else { // 单个删除
					const loading = this.$loading({
						lock: true
					});
					this.$http.post('/order/helpdel', {
						id: row.id
					}).then(res => {
						loading.close();
						if (res.data.code === 0) {
							this.$message({
								type: 'success',
								message: res.data.msg
							});
							this.$refs.table.reload();
						} else {
							this.$message.error(res.data.msg);
						}
					}).catch(e => {
						loading.close();
						this.$message.error(e.message);
					});
				}
			},
			// 手动结束确认
			order_status_update(row) {
				this.$refs['overOrderList'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/order/order_status_update', {
							id: row.id,
							order_id: row.order_id,
							finish: this.overOrderList2.finish,
						}).then(res => {
							loading.close();

							if (res.data.code === 0) {
								this.overOrderShow = false;
								this.overOrderList2.finish = ''
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.overOrderShow = false;
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							this.overOrderShow = false;
							loading.close();
							this.$message.error(e.message);
						});
					}
				})
			},
			/*** 手动接收*/
			overOrder(row) {
				this.overOrderList = row
				this.overOrderShow = true
			},
			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				//console.log('order data export');
				this.$http
					.get("/order/exportExcelLivery", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

<style scoped>
	.bgGreen {
		background: #89B929 !important;
		color: #fff;
	}

	.ele-block .el-upload-dragger {
		width: 100%;
	}

	.changeStyle {
		width: 150px;
		margin-right: 30px;
	}

	.el-upload-list el-upload-list--text {
		display: none !important
	}

	.el-form-item-label {
		width: 20%;
		text-align: left
	}

	.disCenter {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.mr-10 {
		margin-right: 10px;
	}

	.el-date-editor /deep/.el-input,
	.el-date-editor /deep/.el-input__inner {
		width: 100% !important;
	}

	/deep/.el-dialog {
		margin-top: 0px !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-width: 1400px;
	}

	.el-col {
		border-radius: 4px;
	}

	.bg-purple-dark {
		background: #99a9bf;
	}

	.bg-purple {
		background: #fff;
		height: 160px;
	}

	.bg-purple-light {
		background: #e5e9f2;
	}

	.grid-content {
		border-radius: 4px;
		min-height: 36px;
	}

	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}

	.smsInner {
		border-bottom: 1px solid #ddd;
		padding-bottom: 14px;
	}

	.itemBox {
		width: 100%;
		display: flex;
		justify-content: flex-start;
	}
</style>